.App {
  text-align: center;
}

body {
  background-color: #cebeb9 ;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-header {
  /* background-color:white; */
  color:black;
  background-color: #cebeb9;
}

.header-text {
  font-size: 20px; /* Set the desired font size */
}

@media (max-width: 600px) {
  /* Override styles for .app-header on smaller screens */
  .app-header {
    white-space: nowrap;
    font-size: 12px;
  }

  /* Override styles for .header-text on smaller screens */
  .header-text {
    white-space: nowrap;
    font-size: 16px;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }

}


/* HolidayData.css */
.holiday-chart-container {
  margin-top: 20px;
  text-align: center;
  background-color: #cebeb9;
  padding-bottom: 50px; /* Default paddings */
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .holiday-chart-container {
    padding-bottom: 0; /* Remove padding for smaller screens */
  }
}

.holiday-chart-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
.holiday-chart-table thead .first-row th {
  background-color: #cebeb9;

}


.holiday-chart-table th,
.holiday-chart-table td {
  padding: 8px;
  border: 1px solid #ddd;
  background-color: #cebeb9;
}

.holiday-chart-table th {
  background-color: #f2f2f2;
}

.holiday-chart-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.holiday-chart-table tr:hover {
  background-color: #e6e6e6;
}

.holiday-title-format {
  white-space: nowrap;
}

.year-select {
  text-align: left;
  margin-left: 1rem;
  width: 90px;
  padding-bottom: 10px;
}

.year-select-title {
  text-align: left;
  color: grey;
  margin-bottom: 10px;
  width: 150px;
}


/* AboutModal.css */
/* .about-button {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 999;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px; */
  /* color: #333; */
  /* color: rgb(21, 21, 158);
  text-decoration: underline;
} */

.modal-content {
  /* Add styles for the modal content here */
}

.about-modal {
  position: fixed;
  top: 0;
  padding-top: 40px;
  right: -400px; /* Adjust the value based on the desired width of the modal */
  width: 400px; /* Adjust the width as needed */
  height: 100vh;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  z-index: 1000;
  background-color: #e6e6e6;
}

.contact-modal {
  position: fixed;
  top: 0;
  padding-top: 40px;
  right: -400px; /* Adjust the value based on the desired width of the modal */
  width: 400px; /* Adjust the width as needed */
  height: 100vh;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  z-index: 1000;
  background-color: #e6e6e6;
}

.about-text {
  font-size: 16px;
  margin-left: 20px;
  margin-right: 20px;
}

.contact-text {
  font-size: 18px;
  margin-left: 20px;
  margin-right: 20px;
}


.about-modal.open {
  right: 0;
}

.contact-modal.open {
  right: 0;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  text-decoration: underline;
}


.total-holidays {
  font-weight: bold;
  font-size: 20px;
  color: black;
}
@media (max-width: 600px) {
  /* Override styles for .app-header on smaller screens */
  .total-holidays {
    font-size: 18px;
  }

}


/* .buttons-container {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
} */

.buttons-container {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media (max-width: 600px) {
  .buttons-container {
    position: static;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 18px;
  }
}




/* @media (max-width: 600px) {
  .buttons-container {
    position: static;
    flex-direction: row;
    /* justify-content: space-between; */
    /* margin-bottom: 10px;
  } */
/* } */ 

.about-button,
.contact-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  /* background-color: #f1f1f1; */
  background-color: transparent;
  color: black;
  cursor: pointer;
  margin-bottom: 0px;
  max-width: 100px;
  width: 100%;
}

.about-button:hover,
.contact-button:hover {
  text-decoration: underline; /* Add this line to underline on hover */
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .about-button,
  .contact-button  {
    text-decoration: underline; /* Underline button text on smaller screens */
  }
}


.bullet-list {
  text-align: left;
  list-style-position: inside;
  padding-left: 60px;
}

.coffee-button-container {
  margin-top: 120px; 
}

.hebcal-info {
  margin-top: 85px; /* Adjust the value as needed */
  font-size: 13px;
}

.extra-holiday-container {
  /* position: relative; */
  text-align: left;
  padding-top: 20px;
  /* padding-bottom: 15px; */
}




